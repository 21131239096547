exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-500-index-jsx": () => import("./../../../src/pages/500/index.jsx" /* webpackChunkName: "component---src-pages-500-index-jsx" */),
  "component---src-pages-bipolar-404-index-jsx": () => import("./../../../src/pages/bipolar/404/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-404-index-jsx" */),
  "component---src-pages-bipolar-500-index-jsx": () => import("./../../../src/pages/bipolar/500/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-500-index-jsx" */),
  "component---src-pages-bipolar-about-bipolar-depression-index-jsx": () => import("./../../../src/pages/bipolar/about-bipolar-depression/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-about-bipolar-depression-index-jsx" */),
  "component---src-pages-bipolar-accessing-caplyta-index-jsx": () => import("./../../../src/pages/bipolar/accessing-caplyta/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-accessing-caplyta-index-jsx" */),
  "component---src-pages-bipolar-adverse-reactions-index-jsx": () => import("./../../../src/pages/bipolar/adverse-reactions/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-adverse-reactions-index-jsx" */),
  "component---src-pages-bipolar-cookie-policy-index-jsx": () => import("./../../../src/pages/bipolar/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-cookie-policy-index-jsx" */),
  "component---src-pages-bipolar-disclosure-information-index-jsx": () => import("./../../../src/pages/bipolar/disclosure-information/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-disclosure-information-index-jsx" */),
  "component---src-pages-bipolar-disease-severity-index-jsx": () => import("./../../../src/pages/bipolar/disease-severity/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-disease-severity-index-jsx" */),
  "component---src-pages-bipolar-dosing-index-jsx": () => import("./../../../src/pages/bipolar/dosing/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-dosing-index-jsx" */),
  "component---src-pages-bipolar-eps-akathisia-index-jsx": () => import("./../../../src/pages/bipolar/eps-akathisia/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-eps-akathisia-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-attributes-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/attributes/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-attributes-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-differences-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/differences/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-differences-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-dose-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/dose/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-dose-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-impact-symptoms-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/impact-symptoms/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-impact-symptoms-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-misdiagnosis-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/misdiagnosis/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-misdiagnosis-index-jsx" */),
  "component---src-pages-bipolar-expert-videos-video-transcripts-safety-profile-index-jsx": () => import("./../../../src/pages/bipolar/expert-videos/video-transcripts/safety-profile/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-expert-videos-video-transcripts-safety-profile-index-jsx" */),
  "component---src-pages-bipolar-indications-index-jsx": () => import("./../../../src/pages/bipolar/indications/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-indications-index-jsx" */),
  "component---src-pages-bipolar-pharmacology-index-jsx": () => import("./../../../src/pages/bipolar/pharmacology/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-pharmacology-index-jsx" */),
  "component---src-pages-bipolar-primary-efficacy-index-jsx": () => import("./../../../src/pages/bipolar/primary-efficacy/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-primary-efficacy-index-jsx" */),
  "component---src-pages-bipolar-quality-of-life-index-jsx": () => import("./../../../src/pages/bipolar/quality-of-life/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-quality-of-life-index-jsx" */),
  "component---src-pages-bipolar-resources-index-jsx": () => import("./../../../src/pages/bipolar/resources/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-resources-index-jsx" */),
  "component---src-pages-bipolar-samples-index-jsx": () => import("./../../../src/pages/bipolar/samples/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-samples-index-jsx" */),
  "component---src-pages-bipolar-savings-card-index-jsx": () => import("./../../../src/pages/bipolar/savings-card/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-savings-card-index-jsx" */),
  "component---src-pages-bipolar-sitemap-index-jsx": () => import("./../../../src/pages/bipolar/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-sitemap-index-jsx" */),
  "component---src-pages-bipolar-video-transcripts-commercial-jsx": () => import("./../../../src/pages/bipolar/video-transcripts/commercial.jsx" /* webpackChunkName: "component---src-pages-bipolar-video-transcripts-commercial-jsx" */),
  "component---src-pages-bipolar-weight-metabolics-index-jsx": () => import("./../../../src/pages/bipolar/weight-metabolics/index.jsx" /* webpackChunkName: "component---src-pages-bipolar-weight-metabolics-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schizophrenia-cookie-policy-index-jsx": () => import("./../../../src/pages/schizophrenia/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-cookie-policy-index-jsx" */),
  "component---src-pages-schizophrenia-disclosure-information-index-jsx": () => import("./../../../src/pages/schizophrenia/disclosure-information/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-disclosure-information-index-jsx" */),
  "component---src-pages-schizophrenia-dosing-index-jsx": () => import("./../../../src/pages/schizophrenia/dosing/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-dosing-index-jsx" */),
  "component---src-pages-schizophrenia-efficacy-panss-subscales-index-jsx": () => import("./../../../src/pages/schizophrenia/efficacy/panss-subscales/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-efficacy-panss-subscales-index-jsx" */),
  "component---src-pages-schizophrenia-efficacy-panss-total-score-index-jsx": () => import("./../../../src/pages/schizophrenia/efficacy/panss-total-score/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-efficacy-panss-total-score-index-jsx" */),
  "component---src-pages-schizophrenia-efficacy-prosocial-subscales-index-jsx": () => import("./../../../src/pages/schizophrenia/efficacy/prosocial-subscales/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-efficacy-prosocial-subscales-index-jsx" */),
  "component---src-pages-schizophrenia-index-jsx": () => import("./../../../src/pages/schizophrenia/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-index-jsx" */),
  "component---src-pages-schizophrenia-patient-savings-support-index-jsx": () => import("./../../../src/pages/schizophrenia/patient-savings-support/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-patient-savings-support-index-jsx" */),
  "component---src-pages-schizophrenia-pharmacology-index-jsx": () => import("./../../../src/pages/schizophrenia/pharmacology/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-pharmacology-index-jsx" */),
  "component---src-pages-schizophrenia-references-index-jsx": () => import("./../../../src/pages/schizophrenia/references/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-references-index-jsx" */),
  "component---src-pages-schizophrenia-safety-adverse-reactions-index-jsx": () => import("./../../../src/pages/schizophrenia/safety/adverse-reactions/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-safety-adverse-reactions-index-jsx" */),
  "component---src-pages-schizophrenia-safety-extrapyramidal-symptoms-index-jsx": () => import("./../../../src/pages/schizophrenia/safety/extrapyramidal-symptoms/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-safety-extrapyramidal-symptoms-index-jsx" */),
  "component---src-pages-schizophrenia-safety-metabolic-endocrine-index-jsx": () => import("./../../../src/pages/schizophrenia/safety/metabolic-endocrine/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-safety-metabolic-endocrine-index-jsx" */),
  "component---src-pages-schizophrenia-sitemap-index-jsx": () => import("./../../../src/pages/schizophrenia/sitemap/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-sitemap-index-jsx" */),
  "component---src-pages-schizophrenia-telemedicine-index-jsx": () => import("./../../../src/pages/schizophrenia/telemedicine/index.jsx" /* webpackChunkName: "component---src-pages-schizophrenia-telemedicine-index-jsx" */)
}

