import { debounce } from '@src/utils/helper.js';

let distanceScrolled = -1;
let distanceThreshold = [
	{ threshold: 0, value: 'Baseline', sent: false },
	{ threshold: 25, value: '25%', sent: false },
	{ threshold: 50, value: '50%', sent: false },
	{ threshold: 75, value: '75%', sent: false },
	{ threshold: 100, value: '100%', sent: false },
];

const scrollDepth = () => {
	const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
	let progress = Math.round(
		(scrollTop / (document.body.scrollHeight - window.innerHeight)) * 100
	);

	if (isNaN(progress)) progress = 0;

	if (progress > distanceScrolled) {
		distanceScrolled = progress;

		for (let i = 0; i < distanceThreshold.length; i++) {
			const status = distanceThreshold[i].sent;
			const threshold = distanceThreshold[i].threshold;
			const value = distanceThreshold[i].value;

			if (!status && progress >= threshold) {
				if ('dataLayer' in window) {
					window.dataLayer.push({
						event: 'gatsbyScroll',
						gatsbyScrollDistance: value,
					});
					distanceThreshold[i].sent = true;
				}
			}
		}
	}
};

const scrollListener = debounce(scrollDepth, 18);

// ISI Scroll
let distanceScrolledIsi = -1
let distanceThresholdIsi = [
    { threshold: 0, value: 'Baseline', sent: false },
    { threshold: 25, value: '25%', sent: false },
    { threshold: 50, value: '50%', sent: false },
    { threshold: 75, value: '75%', sent: false },
    { threshold: 100, value: '100%', sent: false },
]

const scrollDepthIsi = () => {
    const sidebarIsi = document.querySelector('aside section')
    const scrollTop = sidebarIsi.scrollTop

    let progress = Math.round(
        (scrollTop / (sidebarIsi.scrollHeight - sidebarIsi.clientHeight)) * 100
    )
    if (isNaN(progress)) progress = 0

    if (progress > distanceScrolledIsi) {
        distanceScrolledIsi = progress

        for (let i = 0; i < distanceThresholdIsi.length; i++) {
            const status = distanceThresholdIsi[i].sent
            const threshold = distanceThresholdIsi[i].threshold
            const value = distanceThresholdIsi[i].value

            if (!status && progress >= threshold) {
                if ("dataLayer" in window) {
                    window.dataLayer.push({
                        event: "isiScroll",
                        isiScrollDistance: value,
                    })
                    distanceThresholdIsi[i].sent = true
                }
            }
        }
    }
}

const scrollListenerIsi = debounce(scrollDepthIsi, 18);

const getVideoFilename = (video) => {
	const videoSource = video.querySelector('source');
	if (videoSource && videoSource.src) {
        if (videoSource.src.includes('vimeo')) {
            return videoSource.src;
        } else {
            const src = videoSource.src.split('/');
            return src[src.length - 1];
        }
	}
	return null;
};

const videoPlayListener = (video) => {
	if (video) {
		const filename = getVideoFilename(video);
		window.dataLayer.push({
			event: 'videoStart',
			videoCategory: 'Video-Plays',
			videoFile: filename,
			videoStatus: 'Start',
		});
	}
};

const videoEndListener = (video) => {
	if (video) {
		const filename = getVideoFilename(video);
		window.dataLayer.push({
			event: 'videoEnd',
			videoCategory: 'Video-Plays',
			videoFile: filename,
			videoStatus: 'End',
		});
	}
};

const videoPercentages = [
	{ percentage: '10%', value: 0.1, sent: false },
	{ percentage: '20%', value: 0.2, sent: false },
	{ percentage: '30%', value: 0.3, sent: false },
	{ percentage: '40%', value: 0.4, sent: false },
	{ percentage: '50%', value: 0.5, sent: false },
	{ percentage: '60%', value: 0.6, sent: false },
	{ percentage: '70%', value: 0.7, sent: false },
	{ percentage: '80%', value: 0.8, sent: false },
	{ percentage: '90%', value: 0.9, sent: false },
	{ percentage: '100%', value: 1, sent: false },
];

let videoSeconds = [];

const videoTime = (video) => {
	if (video) {
		const filename = getVideoFilename(video);

		// Send video event after time lapsed, every 10% interval
		const currentPercentage = video.currentTime / video.duration;
		const unsentIndex = videoPercentages.findIndex((percentageValue) => {
			const currentPercentageValue = (
				Math.floor(currentPercentage * 10) / 10
			).toFixed(1);
			return (
				!percentageValue.sent &&
				Number(currentPercentageValue) === percentageValue.value
			);
		});
		if (unsentIndex >= 0) {
			window.dataLayer.push({
				event: 'videoPercentage',
				videoCategory: 'Video-Interval-Percent',
				videoFile: filename,
				videoPercentage: videoPercentages[unsentIndex].percentage,
			});
			videoPercentages[unsentIndex].sent = true;
		}

		// Send video event after time lapsed, every 10s interval
		for (let i = 0; i < video.duration; i += 10) {
			if (!videoSeconds.find((threshold) => threshold.value === i)) {
				videoSeconds.push({ value: i, sent: false });
			}
		}
		const secondsIndex = videoSeconds.findIndex((threshold) => {
			const currentSeconds = Math.floor(video.currentTime / 10) * 10;
			return !threshold.sent && Number(currentSeconds) === threshold.value;
		});
		if (secondsIndex >= 0) {
			window.dataLayer.push({
				event: 'videoSeconds',
				videoCategory: 'Video-Interval-Seconds',
				videoFile: filename,
				videoSeconds: videoSeconds[secondsIndex].value,
			});
			videoSeconds[secondsIndex].sent = true;
		}
	}

};

const videoTimeListener = debounce(videoTime, 100);
let sczTimer20Sec;
let timer = null;
let counter = 0;

const GTM = {
    handleRoute: () => {
        const sczTimer = () => {
            if (window.location.pathname.includes("schizophrenia")) {
                if (!sczTimer20Sec) {
                    sczTimer20Sec = setTimeout(() => {
                        window.dataLayer.push({
                            event: "schizophrenia20Seconds",
                        })
                    }, 20000)
                }
            } else {
                clearTimeout(sczTimer20Sec)
            }
        }

        const clearTimer = () => {
            clearInterval(timer);
            timer = null;
            counter = 0;
        }

        clearTimer();

        timer = setInterval(() => {
            if ("dataLayer" in window && "google_tag_manager" in window) {
                clearTimer();
                sczTimer();
            } else if (counter === 60) {
                clearTimer();
            }

            counter++
        }, 500);
    },

	init: () => {
		const interval = setInterval(() => {
			if ('dataLayer' in window && 'google_tag_manager' in window) {
				window.dataLayer.push({ event: 'gatsby-route-change' });
				clearInterval(interval);
			}
		}, 100);
		scrollDepth();
		document.addEventListener('scroll', scrollListener);
	},
    initIsi: () => {
        scrollDepthIsi()
        const sidebarIsi = document.querySelector('aside section')
        if (sidebarIsi) {
            sidebarIsi.addEventListener("scroll", scrollListenerIsi)
        }
    },
	initVideo: (video) => {
		if ('dataLayer' in window) {
			video.addEventListener('play', () => videoPlayListener(video));
			video.addEventListener('ended', () => videoEndListener(video));
			video.addEventListener('timeupdate', () => videoTimeListener(video));
		}
	},
	initPlyrVideo: (player) => {
		if ('dataLayer' in window) {
			if (player) {
				player.on('play', (e) => {
					const video = e.target.querySelector('video');
					videoPlayListener(video);
				});

				player.on('ended', (e) => {
					const video = e.target.querySelector('video');
					videoEndListener(video);
				});

				player.on('timeupdate', (e) => {
					const video = e.target.querySelector('video');
					videoTimeListener(video);
				});
			}
		}
	},
	inputChange: () => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'inputChange',
				fieldValue: 'npi_number',
				formName: 'evoucher form',
			});
		}
	},
	invalidNPI: (fieldError) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({ event: 'invalidNPI', fieldError });
		}
	},
	sendVouchersRemaining: (numOfVouchers, availableVouchers) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'vouchersRemaining',
				numOfVouchers,
				availableVouchers,
			});
		}
	},
	vermontQuestion: (vermontAnswer) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({ event: 'vermontQuestion', vermontAnswer });
		}
	},
	vermontQuestionForm: (vermontAnswer, fieldValue) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'vermontQuestionForm',
				fieldValue: vermontAnswer,
				formName: 'evoucher form',
				vermontAnswer,
			});
		}
	},
	voucherAmountSelected: (voucherAmount, fieldValue) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'voucherAmount',
				voucherAmount,
				fieldName: 'Selected amount of vouchers',
				fieldValue: voucherAmount,
				formName: 'evoucher form',
			});
		}
	},
	fieldCompletion: (fieldValue) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({ event: 'fieldCompletion', fieldValue });
		}
	},
	formSubmission: () => {
		if ('dataLayer' in window) {
			window.dataLayer.push({ event: 'formCompletion', formName: 'evoucher form' });
		}
	},
	formFieldCompletionFormSubmitted: (fieldName, fieldValue) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'formFieldCompletionFormSubmitted',
				formName: 'evoucher form',
				fieldName,
				fieldValue,
			});
		}
	},
	toggleAccordionBp: (isExpanded, accHeading) => {
		if ('dataLayer' in window) {
			window.dataLayer.push({
				event: 'toggleAccordionBp',
				accordionState: isExpanded ? 'Expand' : 'Collapse',
				accordionLabel: accHeading,
			});
		}
	},
	toggleAccordion: (isExpanded, accHeading) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({ event: 'toggleAccordion', accordionState: isExpanded ? 'Expand' : 'Collapse', accordionLabel: accHeading });
        }
    },
	toggleAllAccordions: (isExpanded, accHeading) => {
        if ('dataLayer' in window) {
            window.dataLayer.push({ event: 'toggleAllAccordions', accordionState: isExpanded ? 'Expand' : 'Collapse', accordionLabel: accHeading });
        }
    },
	reset: () => {
		videoPercentages.forEach((threshold) => (threshold.sent = false));
		videoSeconds = [];
		distanceScrolled = -1;
		distanceThreshold.forEach((threshold) => (threshold.sent = false));
        distanceScrolledIsi = -1;
		distanceThresholdIsi.forEach((threshold) => (threshold.sent = false));
        sczTimer20Sec = null;
        timer = null;
        counter = 0;
	},
};

export default GTM;
