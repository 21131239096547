import React, { useState } from "react"

const defaultState = {
    modalOpen: false,
    medicalModalOpen: false,
    externalUrl: null,
    showModal: () => {},
    showMedicalModal: () => {},
    setExternalUrl: () => {},
    menuOpen: false,
    showMenu: () => {},
    modalTrigger: null,
    setModalTrigger: () => {},
    videoModalOpen: false,
    showVideoModal: () => {},
    videoUrl: null,
    setVideoUrl: () => {},
    telemedBannerOpen: true,
    setTelemedBanner: () => {}
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [modalOpen, showModal] = useState(false)
    const [medicalModalOpen, showMedicalModal] = useState(false)
    const [url, setUrl] = useState(null)
    const [menuOpen, showMenu] = useState(false)
    const [modalTrigger, setModalTrigger] = useState(null)
    const [videoModalOpen, showVideoModal] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null)
    const [telemedBannerOpen, setTelemedBanner] = useState(true)

    const store = {
        modalOpen: modalOpen,
        showModal: showModal,
        medicalModalOpen: medicalModalOpen,
        showMedicalModal: showMedicalModal,
        externalUrl: url,
        setExternalUrl: setUrl,
        menuOpen: menuOpen,
        showMenu: showMenu,
        modalTrigger: modalTrigger,
        setModalTrigger: setModalTrigger,
        videoModalOpen: videoModalOpen,
        showVideoModal: showVideoModal,
        videoUrl: videoUrl,
        setVideoUrl: setVideoUrl,
        telemedBannerOpen: telemedBannerOpen,
        setTelemedBanner: setTelemedBanner
    }

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
