import React from 'react';
import { AppProvider } from './src/context';
import GTM from '@src/utils/GTM.js';

export const onClientEntry = () => {
	window.sessionStorage.removeItem('telemedbanner-seen');
};

export const shouldUpdateScroll = () => {
	window.scrollTo(0, 0);
	return false;
};

export const onRouteUpdate = ({ location }) => {
	GTM.reset();
	GTM.handleRoute();

	const hash = location?.hash;

	window.setTimeout(() => {
		if (hash) {
			const el = document.querySelector(hash);
			if (el) el.scrollIntoView();
		}
	}, 500);
};

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;
